<template lang="pug">
    div
        template(v-if="userLanguage == 'fr'")
            div.question.block.col-9(:class="{activated: toggleQuestions[0] === true}" @click="toggleQuestions[0] = toggleQuestions[0] ? false : true;$forceUpdate()")
                img.icon.icon-24.dropdown-icon(src="./../../assets/icon/dropdown-icon.svg" :class="{activated: toggleQuestions[0] === true}")
                p.text-averta-semibold-20 Comment accéder à mon profil utilisateur ?
                p.content(v-if="toggleQuestions[0] === true") 
                    | Passez votre souris sur votre photo de profil, ou vos initiales si vous n’avez pas encore ajouté de photo, en haut à droite de la page. 
                    br
                    | Cliquez sur « Mon profil ». 
                    br
                    | Vous êtes alors redirigé sur la page de votre profil utilisateur. 

            div.question.block.col-9(:class="{activated: toggleQuestions[1] === true}" @click="toggleQuestions[1] = toggleQuestions[1] ? false : true;$forceUpdate()")
                img.icon.icon-24.dropdown-icon(src="./../../assets/icon/dropdown-icon.svg" :class="{activated: toggleQuestions[1] === true}")
                p.text-averta-semibold-20 Comment puis-je accéder au profil utilisateur d'une autre personne ? 
                p.content(v-if="toggleQuestions[1] === true") 
                    | Il y a plusieurs façons de le faire :   
                    br
                    br 
                    ul 
                        li - Recherchez dans l'annuaire puis cliquez sur l'utilisateur, vous serez redirigé vers son profil. 
                        li - Tapez le nom de la personne directement via le moteur de recherche de la plateforme.   
                        li - Cliquez sur le nom de l'utilisateur n'importe où sur la plateforme, vous serez également redirigé vers son profil. 
                    | Vous pourrez alors voir les informations relatives à l'utilisateur : compétences, chapitres, dernières publications (Article, Demande). 

        template(v-if="userLanguage == 'en'")
            div.question.block.col-9(:class="{activated: toggleQuestions[0] === true}" @click="toggleQuestions[0] = toggleQuestions[0] ? false : true;$forceUpdate()")
                img.icon.icon-24.dropdown-icon(src="./../../assets/icon/dropdown-icon.svg" :class="{activated: toggleQuestions[0] === true}")
                p.text-averta-semibold-20 How to access my user profile?
                p.content(v-if="toggleQuestions[0] === true")
                    | Move your mouse over your profile photo, or initials of your name if you haven't added a photo yet, at the top right of the page.
                    br
                    | Click "My Profil".
                    br
                    | You are redirected to your user profile page.

            div.question.block.col-9(:class="{activated: toggleQuestions[1] === true}" @click="toggleQuestions[1] = toggleQuestions[1] ? false : true;$forceUpdate()")
                img.icon.icon-24.dropdown-icon(src="./../../assets/icon/dropdown-icon.svg" :class="{activated: toggleQuestions[1] === true}")
                p.text-averta-semibold-20 How do I access another person's user profile?
                p.content(v-if="toggleQuestions[1] === true")
                    | There are several ways to do this:
                    br
                    br
                    ul
                        li - Search in the directory then click on the user, you will be redirected to his profile.
                        li - Type the person's name directly via the platform's search engine.
                        li - Click on the user's name anywhere on the platform, you will also be redirected to his profile.
                    | You will then be able to see the information related to the user: skills, chapters, the latest publications (Article, Ask For it).

        template(v-if="userLanguage == 'zh-cn'")
            div.question.block.col-9(:class="{activated: toggleQuestions[0] === true}" @click="toggleQuestions[0] = toggleQuestions[0] ? false : true;$forceUpdate()")
                img.icon.icon-24.dropdown-icon(src="./../../assets/icon/dropdown-icon.svg" :class="{activated: toggleQuestions[0] === true}")
                p.text-averta-semibold-20 如何查看我的个人资料?
                p.content(v-if="toggleQuestions[0] === true")
                    | 1. 将鼠标移到页面右上方的个人头像上，如果您还没有添加头像，则会显示名字的首字母。
                    br
                    | 2. 点击 "我的资料"。
                    br
                    | 3. 将跳转至个人资料页面。

            div.question.block.col-9(:class="{activated: toggleQuestions[1] === true}" @click="toggleQuestions[1] = toggleQuestions[1] ? false : true;$forceUpdate()")
                img.icon.icon-24.dropdown-icon(src="./../../assets/icon/dropdown-icon.svg" :class="{activated: toggleQuestions[1] === true}")
                p.text-averta-semibold-20 如何查看其他成员的资料?
                p.content(v-if="toggleQuestions[1] === true")
                    | 1. 在通讯录中搜索，然后点击成员查看他的资料。
                    br
                    | 2. 在平台的搜索引擎中直接输入成员名字。
                    br
                    | 3. 在平台的任何地方点击成员的名字查看他的资料。
                    br
                    | 4. 然后你就可以看到该成员的相关信息：技能、群组、最新动态（文章、你问我答）。
</template>

<script lang="ts">
import Vue from 'vue'
export default Vue.extend({
    
    data() {
        return {
            toggleQuestions: []
        }
    },

    computed: {
        userLanguage() {
            return this.$store.getters['language/getUserLanguage']
        }
    }

})
</script>

<style lang="scss" scoped>
@import './question.scss';
</style>